





























































/**
 * Component capable to render Pagination element.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
import { defineComponent, SetupContext } from '@vue/composition-api'

import { CustomLink } from '../../atoms/Link'

import { paginationProps, usePagination } from './Pagination.hooks'
import { PaginationProps } from './Pagination.contracts'

export const Pagination = defineComponent({
  name: 'Pagination',
  components: { CustomLink },
  props: paginationProps,
  emits: ['change'],

  setup (props: PaginationProps, ctx: SetupContext) {
    return usePagination(props, ctx.emit)
  }
})

export default Pagination
