// Copyright © 2021 Move Closer

import { computed, PropType } from '@vue/composition-api'
import { ComponentObjectPropsOptions, Emit } from '../../../vue-api'

import {
  AbstractPaginationProps,
  PAGINATION_NEXT,
  PAGINATION_PREV,
  UsePaginationProvides
} from './Pagination.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl> (edited)
 */
export const abstractPaginationProps: ComponentObjectPropsOptions<AbstractPaginationProps> = {
  currentPage: {
    type: Number,
    required: false,
    default: 1
  },

  showDirectionButtons: {
    type: Boolean,
    required: false,
    default: true
  },

  totalPages: {
    type: Number,
    required: true
  },

  totalVisible: {
    type: Number as PropType<number | undefined>,
    required: false
  },

  groupedLayout: {
    type: Boolean,
    required: false,
    default: false
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const usePagination = (
  props: AbstractPaginationProps,
  emit: Emit
): UsePaginationProvides => {
  /**
   * Emits the `@change` event with the passed-in page number as an event payload.
   *
   * @param page - The page that we want to go to.
   */
  const goToPage = (page: number): void => {
    emit('change', page)
  }

  /**
   * If the routing is possible, calls for the next page.
   */
  const goToNextPage = (): void => {
    if (props.currentPage < props.totalPages) {
      goToPage(props.currentPage + 1)
    }
  }

  /**
   * If the routing is possible, calls for the previous page.
   */
  const goToPrevPage = (): void => {
    if (props.currentPage > 0) {
      goToPage(props.currentPage - 1)
    }
  }

  /**
   * @author Vuetify
   *
   * @see https://github.com/vuetifyjs/vuetify/blob/6bb948463c2e9559d60f5f99e1208f79a0935b39/packages/vuetify/src/components/VPagination/VPagination.ts#L170
   */
  const range = (from: number, to: number) => {
    const range = []

    from = from > 0 ? from : 1

    for (let i = from; i <= to; i++) {
      range.push(i)
    }

    return range
  }

  /**
   * @see UsePaginationProvides.canGoBack
   */
  const canGoBack = computed<boolean>(() => props.currentPage > 1)

  /**
   * @see UsePaginationProvides.canGoNext
   */
  const canGoNext = computed<boolean>(() => props.currentPage < props.totalPages)

  const getEquallyDistributedItems = (maxLength: number) => {
    const even = maxLength % 2 === 0 ? 1 : 0
    const left = Math.floor(maxLength / 2)
    const right = props.totalPages - left + 1

    if (props.currentPage > left && props.currentPage < right) {
      const firstItem = 1
      const lastItem = props.totalPages
      const start = props.currentPage - left + 2 - (props.currentPage === firstItem ? 0 : 1)
      const end = props.currentPage + left - 2 + (props.currentPage === props.totalPages - 1 ? -1 : 1) // 1
      const secondItem = start === firstItem ? 2 : '...'
      const beforeLastItem = end === lastItem - 1 ? null : '...'

      return [1, secondItem, ...range(start, end), beforeLastItem, props.totalPages]
    } else if (props.currentPage === left) {
      const end = props.currentPage + left - even
      return [...range(1, end), '...', props.totalPages]
    } else if (props.currentPage === right) {
      const start = props.currentPage - left + 1
      return [1, '...', ...range(start, props.totalPages)]
    } else {
      return [
        ...range(1, left),
        '...',
        ...range(right, props.totalPages)
      ]
    }
  }

  const getGroupedItems = (maxLength: number) => {
    const firstItem = 1
    const lastItem = props.totalPages
    const groupWidth = maxLength - 2
    const right = lastItem - groupWidth

    if (props.currentPage <= groupWidth) {
      return [...range(1, groupWidth + 1), PAGINATION_NEXT, '...', lastItem]
    } else if (props.currentPage >= groupWidth && props.currentPage < right) {
      return [firstItem, '...', PAGINATION_PREV, ...range(props.currentPage, props.currentPage + groupWidth - 1), PAGINATION_NEXT, '...', lastItem]
    } else {
      const start = lastItem - groupWidth
      return [firstItem, '...', PAGINATION_PREV, ...range(start, lastItem)]
    }
  }

  /**
   * @author Vuetify
   *
   * @see UsePaginationProvides.items
   * @see https://github.com/vuetifyjs/vuetify/blob/6bb948463c2e9559d60f5f99e1208f79a0935b39/packages/vuetify/src/components/VPagination/VPagination.ts#L89
   */
  const items = computed<Array<any>>(() => {
    let totalVisible = props.totalVisible

    if (typeof totalVisible !== 'number') {
      totalVisible = props.totalPages
    }

    if (totalVisible === 0) {
      return []
    }

    const maxLength = Math.min(
      Math.max(0, totalVisible) || props.totalPages,
      props.totalPages
    )

    if (props.totalPages <= maxLength) {
      return range(1, props.totalPages)
    }

    if (props.groupedLayout) {
      return getGroupedItems(maxLength)
    } else {
      return getEquallyDistributedItems(maxLength)
    }
  })

  /**
   * @see UsePaginationProvides.getJumpBackPage
   */
  const getJumpBackPage = (items: Array<string | number>): number | null => {
    const index = items.indexOf(PAGINATION_PREV)
    const nextItem = items[index + 1]

    if (typeof nextItem !== 'number') {
      return null
    }

    return Math.min(nextItem - 1, props.totalPages)
  }

  /**
   * @see UsePaginationProvides.getJumpForwardPage
   */
  const getJumpForwardPage = (items: Array<string | number>): number | null => {
    const index = items.indexOf(PAGINATION_NEXT)
    const prevItem = items[index - 1]

    if (typeof prevItem !== 'number') {
      return null
    }

    return Math.min(prevItem + 1, props.totalPages)
  }

  /**
   * @see UsePaginationProvides.onJumpBackClick
   */
  const onJumpBackClick = (items: Array<string | number>): void => {
    const page = getJumpBackPage(items)

    if (page) {
      goToPage(page)
    }
  }

  /**
   * @see UsePaginationProvides.onJumpForwardClick
   */
  const onJumpForwardClick = (items: Array<string | number>): void => {
    const page = getJumpForwardPage(items)

    if (page) {
      goToPage(page)
    }
  }

  /**
   * @see UsePaginationProvides.onNextPageClick
   */
  const onNextPageClick = (): void => {
    goToNextPage()
  }

  /**
   * @see UsePaginationProvides.onDirectPageClick
   */
  const onPageClick = (page: number): void => {
    if (
      typeof page !== 'number' ||
      page === props.currentPage ||
      page < 0 ||
      page > props.totalPages
    ) {
      return
    }

    goToPage(page)
  }

  /**
   * @see UsePaginationProvides.onPrevPageClick
   */
  const onPrevPageClick = (): void => {
    goToPrevPage()
  }

  return {
    canGoBack,
    canGoNext,
    items,
    getJumpBackPage,
    getJumpForwardPage,
    onJumpBackClick,
    onJumpForwardClick,
    onNextPageClick,
    onDirectPageClick: onPageClick,
    onPrevPageClick
  }
}
