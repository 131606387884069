// Copyright © 2022 Move Closer

import { ComputedRef, Data } from '@vue/composition-api'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl> (edited)
 */
export interface AbstractPaginationProps extends Data {
  /**
   * Currently-active page.
   */
  currentPage: number

  /**
   * Determines whether the direction buttons (left/right) should be shown.
   */
  showDirectionButtons: boolean

  /**
   * Total sum of all available pages.
   */
  totalPages: number

  /**
   * Determines the maximum number of items (pagination buttons)
   * that can be rendered at once.
   */
  totalVisible: number | undefined

  /**
   * Determines whether pagination should have additional button inside.
   */
  groupedLayout: boolean
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export interface UsePaginationProvides {
  /**
   * Determines whether the User can navigate to the previous page.
   */
  canGoBack: ComputedRef<boolean>

  /**
   * Determines whether the User can navigate to the next page.
   */
  canGoNext: ComputedRef<boolean>

  /**
   * Pagination items to render.
   */
  items: ComputedRef<Array<string | number>>

  /**
   * Handles the `@click` event on the "go to page" button.
   *
   * @param page - Number of the clicked page.
   */
  onDirectPageClick: (page: number) => void

  /**
   * Returns page index to go to after clicking the "jump back" button.
   */
  getJumpBackPage: (items: Array<string | number>) => number | null

  /**
   * Returns page index to go to after clicking the "jump forward" button.
   */
  getJumpForwardPage: (items: Array<string | number>) => number | null

  /**
   * Handles the `@click` event on the "jump back" button.
   */
  onJumpBackClick: (items: Array<string | number>) => void

  /**
   * Handles the `@click` event on the "jump forward" button.
   */
  onJumpForwardClick: (items: Array<string | number>) => void

  /**
   * Handles the `@click` event on the "next page" button.
   */
  onNextPageClick: () => void

  /**
   * Handles the `@click` event on the "previous page" button.
   */
  onPrevPageClick: () => void
}

export const PAGINATION_PREV = -2
export const PAGINATION_NEXT = -1
